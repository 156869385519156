import React from 'react';
import './style.scss';

function ParsedDate({date, marginX, marginY}) {

    // parse date
    date = date.substring(0, 10);
    date = new Date(date);
    let parsedDate = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
  
  return (
 
    <span className={`parsedDate ${marginX ? 'parsedDateMarginX' : ''} ${marginY ? 'parsedDateMarginY' : ''}`}>
      {date ? parsedDate : null}
    </span>
    
  
  );
}
export default ParsedDate;