import React from 'react';
import { connect } from "react-redux";
import { graphql } from 'gatsby';
import {initPosts, initRelatedPosts, initArticleContent, initPictograms, initCountryLinks, initColumnContent, initMicrocopy, initTestimonials, initKeywordsAndSome, initNavItems, initPromoBlocks, initNumberBlocks, initVideos, initPeople } from '../state/app';
import { StaticQuery } from 'gatsby'
import Header from '../components/header';
import Footer from '../components/footer';
import CookieBanner from '../components/cookieBanner';

import SEO from '../components/seo';
/* eslint-disable-next-line */
import Animate from 'animate.css';

import '../_sass/_global.scss';
import './style.scss';

// This is kind of root component where we init Redux data and which wraps all other components

const Layout = ({ children, dispatch, title, description, category, location }) => (
  <StaticQuery
  query={graphql`
    query layoutQuery {
      posts: allContentfulPost (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            id
            title
            slug
            excerpt {
              excerpt
            }
            category
            keywords
            date
            showDate
            heroImageBottom
            heroImage {
              description
              resolutions (quality: 70, width: 400) {
                src
              }
            }
            teaserImage {
              description
              resolutions (quality: 70, width: 400) {
                src
              }
            }
            internal {
              type
            }
          }
        }
      }
      related: allContentfulRelatedPosts (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            title
            slug
            style
            ctaLabel
            ctaLink
            showTitle
            readMoreLabel
            internal {
              type
            }
            links {
              id
              slug
              title
              link
            }
            people {
              id
              slug
              teaserImage {
                resolutions(quality: 70, height: 400) {
                  src
                }
              }
              title
              jobTitle
              linkedin
              peopleCategoryLabel
              phone
              twitter
              unit
              email
              description {
                description
              }
            }
            pages {
              slug
              title
              id
              excerpt {
                excerpt
              }
              heroImage {
                description
                resolutions (quality: 70, width: 400) {
                  src
                }
              }
            }
            pictograms {
              id
              slug
              teaserImage {
                resolutions(quality: 70, height: 400) {
                  src
                }
              }
              title
              excerpt {
                excerpt
              }
            }
            countryLinks {
              id
              slug
              teaserImage {
                resolutions(quality: 70, height: 400) {
                  src
                }
              }
              title
              countrySiteLinks
              category
              excerpt {
                excerpt
              }
            }
            posts {
              slug
              title
              id
              date
              showDate
              category
              excerpt {
                excerpt
              }
              heroImage {
                description
                resolutions (quality: 70, width: 400) {
                  src
                }
              }
              teaserImage {
                description
                resolutions (quality: 70, width: 400) {
                  src
                }
              }
            }
            category
            numberOfPosts
            offset
          }
        }
      }
    articleContent:  allContentfulArticleContent (filter: {slug: {ne: "validator"}}) {
      nodes {
        content {
          json 
          nodeType
          id
          content
          children {
            ... on File {
              id
              name
              absolutePath
            }
          }
          }
        slug
        id
        title
        style
        markdownContent {
          childMarkdownRemark {
            html
          }
        }
        internal {
          type
        }
        }
      }
      navItems: allContentfulNavigation {
        edges {
          node {
            slug
            title
            chooseNavigation
            childContentfulNavigationSubItemsJsonNode {
              items {
                slug
                title
              }
            }
            order
          }
        }
      }
      videos: allContentfulVideo (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            title
            slug
            internal {
              type
            }
            type
            videoId
            description {
              description
            }
          }
        }
      }
      pictograms:  allContentfulPictogram (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            id
            slug
            title
            teaserImage {
              resolutions {
                src
              }
            }
            internal {
              type
            }
            excerpt {
              excerpt
            }
          }
        }
      }
      countryLinks: allContentfulCountryLink (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            internal {
              type
            }
            countrySiteLinks
            excerpt {
              excerpt
            }
            category
            id
            slug
            title
            teaserImage {
              resolutions {
                src
              }
            }
          }
        }
      }
      keywordsAndSome:   allContentfulSomeAndKeywordsDisplay (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            slug
            title
            internal {
              type
            }
            showKeywords
            showSomeShare
            someHeadlineLabel
            keywordsLabel
          }
        }
      }
      promoBlocks: allContentfulPromoBlock (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            id
            internal {
              type
            }
            slug
            title
            promoType
            ctaText2
            ctaText1
            ctaLink2
            ctaLink1
            description {
              description
              childMarkdownRemark {
                html
              }
            }
            promoBlockData {
              countries {
                link
                name
              }
            }
          }
        }
      }
      numberBlocks: allContentfulNumbers (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            label1
            label2
            label3
            number1
            number2
            number3
            slug
            title
            id
            internal {
              type
            }
          }
        }
      }
      testimonials: allContentfulTestimonial (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            title
            slug
            internal {
              type
            }
            name
            quote {
              quote
            }
            image {
              resolutions(height: 300, width: 300, quality: 70) {
                src
              }
            }
          }
        }
      }
      microcopy: allContentfulMicrocopy (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            content {
              childMarkdownRemark {
                html
              }
            }
            singleLineContent
            showMicrocopy
            id
            title
            slug
            internal {
              type
            }
          }
        }
      }
      columnContent: allContentfulColumnContent (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            slug
            title
            id
            internal {
              type
            }
            column1 {
              childMarkdownRemark {
                html
              }
            }
            column2 {
              childMarkdownRemark {
                html
              }
            }
            column3 {
              childMarkdownRemark {
                html
              }
            }          
          }
        }
      }
      people: allContentfulPeople (filter: {slug: {ne: "validator"}}) {
        edges {
          node {
            id
            internal {
              type
            }
            slug
            teaserImage {
              resolutions(quality: 70, height: 300) {
                src
              }
            }
            title
            jobTitle
            linkedin
            phone
            twitter
            peopleCategoryLabel
            unit
            email
            description {
              description
            }
          }
        }
      }
    }
  `}
  render={data => {

    dispatch(initPosts(data.posts.edges));
    dispatch(initRelatedPosts(data.related.edges));
    dispatch(initArticleContent(data.articleContent));
    dispatch(initNavItems(data.navItems.edges));
    dispatch(initPromoBlocks(data.promoBlocks.edges));
    dispatch(initNumberBlocks(data.numberBlocks.edges));
    dispatch(initVideos(data.videos.edges));
    dispatch(initTestimonials(data.testimonials.edges));
    dispatch(initMicrocopy(data.microcopy.edges));
    dispatch(initPeople(data.people.edges));
    dispatch(initKeywordsAndSome(data.keywordsAndSome.edges));
    dispatch(initColumnContent(data.columnContent.edges));
    dispatch(initCountryLinks(data.countryLinks.edges));
    dispatch(initPictograms(data.pictograms.edges));

    return (
      <div>
        <SEO
        title={`Ramirent | ${category ? category + '|' : "" }  ${title}`}
        description={description}
        />
        <CookieBanner /> 
        <Header /> 
        <div className="siteContainer animate__animated  animate__fadeInLeft">
            {children} 
        </div>
        <Footer />
      </div>
    )} }/>
);


export default connect(state => ({
  mainNavOpen: state.app.mainNavOpen
}), null)(Layout);
