import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";


function SEO({ description, lang = 'en', meta, keywords, title, image }) {
  // fallback image
  image = image || '/fallbackSeo.png';
 
  return (
    
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={title}
            meta={[
              {
                name: `description`,
                content: description
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:image`,
                content: image
              },
              {
                property: `og:description`,
                content: description
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:creator`,
                content: description
              },
              {
                name: `twitter:title`,
                content: `Ramirent.com | ${title}`
              },
              {
                name: `twitter:description`,
                content: description
              },
              {
                name: `twitter:image`,
                content: image,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          > 

         
          
          </Helmet>
        );
}

SEO.defaultProps = {
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;


