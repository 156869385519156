import React, { Component } from 'react';
import {Link } from 'gatsby';
import * as R from 'ramda';
import { connect } from "react-redux";
// FOR IE 11
import fetch from 'isomorphic-fetch';
import { polyfill } from "es6-promise";

import keygen from '../../utils/keygen';
import { initUserLocation } from '../../state/app';
import './style.scss';

class Header extends Component {

  constructor(props) {
    super(props);
    this.mainNavClickHandler = this.mainNavClickHandler.bind(this);
    this.countryNavClickHandler = this.countryNavClickHandler.bind(this);
    this.state = {
      navOpen: false,
      countryNavOpen: false,
    }
  }
  componentDidMount() {
    polyfill();

    this.setLocation = async () => {
      // use geoJS API to check user country based on IP called
      const response = await fetch(
        `https://get.geojs.io/v1/ip/country.json`
        );
      let userLocation = await Promise.resolve(response);
      userLocation = await response.json(); 
      this.props.dispatch(initUserLocation(userLocation));
    }
    this.setLocation();   
    
  };

   mainNavClickHandler = () => {  
    this.setState({
      navOpen: !this.state.navOpen,
      countryNavOpen: false
    })
  }

  countryNavClickHandler = () => { 
    this.setState({
      countryNavOpen: !this.state.countryNavOpen
    })
  }

  render() {
    // Main nav items
    const filterMainNavItems = item => item.node.chooseNavigation === 'main';
    let mainNavigationItems = R.filter(filterMainNavItems, this.props.navItems);
    mainNavigationItems = mainNavigationItems.sort((a, b) => (a.node.order > b.node.order) ? 1 : -1);

    // Country picker items
    const filterCountryNavItems = item => item.node.chooseNavigation === 'countrysites';
    const countryNav= R.head(R.filter(filterCountryNavItems, this.props.navItems)).node;
    const countryNavLabel = countryNav.title;
    const countryNavItems = countryNav.childContentfulNavigationSubItemsJsonNode.items;
    
    return (
      <div className="siteHeader animate__animated animate__fadeInRight">
        <div className="siteHeaderContainer">
          <Link to="/" tabIndex="0" aria-label="Home page" className="siteLogoLink" >
            <span className="screen-reader-text">Home page</span>
            <img alt ="" src="/logo-with-loxam.svg" className="siteLogo" />
          </Link>
          <button tabIndex="0" aria-label="Country menu toggle" className="countryNavToggle" onClick={this.countryNavClickHandler}>
            <span>{countryNavLabel}</span> <img alt ="" src="/chevron-down.svg" className="chevronDownBlue" /> <img alt ="" src="/chevron-yellow-down.svg" className="chevronDownYellow" />
          </button>
          {this.state.countryNavOpen ?
            <div className="countryNav">
              <ul>
                {countryNavItems.map(item => {
                  return(
                    <li key={keygen()}><a href={item.slug} target="_blank" rel="noopener noreferrer">{item.title}</a></li>
                  );
                })}
              </ul>
            </div>
            : null}
          <button onClick={this.mainNavClickHandler} tabIndex="0" aria-label="Main menu toggle" className="mainMenuToggle">
            <img aria-label="Main menu toggle" alt ="" src={this.state.navOpen ? "/close.svg" : "/hamburger.svg"} className="menuToggleIcon" />
          </button>
        </div>
        {this.state.navOpen ?
          <div className="mainNav animate__animated  animate__slideInDown animate__faster">
            <div className="mainNavContainer">
            {mainNavigationItems.map(item => {

              return (
                <div className="navTopLevelItem" key={keygen()}>
                  <Link to={`/${item.node.slug}`} onClick={this.mainNavClickHandler}>
                    <h2>{item.node.title}</h2>
                  </Link> 
                  <ul>
                    {item.node.childContentfulNavigationSubItemsJsonNode ?
                    item.node.childContentfulNavigationSubItemsJsonNode.items.map(subitem => {
                      return (
                        <li key={keygen()}><Link to={`/${subitem.slug}`} onClick={this.mainNavClickHandler}>{subitem.title}</Link></li>
                      );
                    }):null}
                  </ul>
                </div>
            
              )
            })}
            </div>
          
          </div> : null}

      </div>
    );
  }
  
}

export default connect(state => ({
  mainNavOpen: state.app.mainNavOpen,
  navItems: state.app.navItems
}), null)(Header);