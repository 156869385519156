import React from 'react';
import * as R from 'ramda';
import { connect } from "react-redux";
import CountryPicker from './countrypicker';
import SomeButton from './someButton';

import './style.scss';

const Footer = ({microcopy, navItems}) => {
  // First column
  const firstCol = R.head(R.filter(item => item.node.slug === 'footer-first-column', microcopy));
  const firstColContent = R.path(['node', 'content', 'childMarkdownRemark', 'html'], firstCol);
  // Second column
  const secondCol = R.head(R.filter(item => item.node.slug === 'footer-second-column', microcopy));
  const secondColContent = R.path(['node', 'content', 'childMarkdownRemark', 'html'], secondCol);
  // Third column
  const thirdCol = R.head(R.filter(item => item.node.slug === 'footer-third-column', microcopy));
  const thirdColContent = R.path(['node', 'content', 'childMarkdownRemark', 'html'], thirdCol);
  // Fourth column
  const fourthCol = R.head(R.filter(item => item.node.slug === 'footer-fourth-column', microcopy));
  // Footer Some
  const footerFacebook = R.head(R.filter(item => item.node.slug === 'footer-facebook', microcopy));
  const footerYoutube = R.head(R.filter(item => item.node.slug === 'footer-youtube', microcopy));
  const footerTwitter = R.head(R.filter(item => item.node.slug === 'footer-twitter', microcopy));
  const footerLinkedin = R.head(R.filter(item => item.node.slug === 'footer-linkedin', microcopy));
  const footerInstagram = R.head(R.filter(item => item.node.slug === 'footer-instagram', microcopy));
  // Bottom links
  const footerBottomLink1 = R.head(R.filter(item => item.node.slug === 'footer-bottom-link-1', microcopy));
  const footerBottomLink1Label = R.path(['node', 'content', 'childMarkdownRemark', 'html'], footerBottomLink1);
  const footerBottomLink2 = R.head(R.filter(item => item.node.slug === 'footer-bottom-link-2', microcopy));
  const footerBottomLink2Label = R.path(['node', 'content', 'childMarkdownRemark', 'html'], footerBottomLink2);
  const footerBottomLink3 = R.head(R.filter(item => item.node.slug === 'footer-bottom-link-3', microcopy));
  const footerBottomLink3Label = R.path(['node', 'content', 'childMarkdownRemark', 'html'], footerBottomLink3);
     // Country picker items
     const filterCountryNavItems = item => item.node.chooseNavigation === 'countrysites';
     const countryNav = R.head(R.filter(filterCountryNavItems, navItems)).node;
     const countryNavLabel = countryNav.title;
     const countryNavItems = countryNav.childContentfulNavigationSubItemsJsonNode.items;
    return (
      <div className="siteFooter">
        <div className="footerGrid siteHeaderContainer">
          <div className="firstCol footerCol">
          {firstCol && firstCol.node.showMicrocopy ? 
            <>
            <img src="/nega-logo-with-loxam.svg" alt="ramirent" />
            <div dangerouslySetInnerHTML={{ __html: firstColContent ? firstColContent : null }}  />
            </>
            : null}
          </div>
          <div className="secondCol footerCol">
          {secondCol && secondCol.node.showMicrocopy ? 
            <>
            <h3>{secondCol.node.singleLineContent ? secondCol.node.singleLineContent : null}</h3>
            <div dangerouslySetInnerHTML={{ __html: secondColContent ? secondColContent : '' }}  />
            </>
            : null}
          </div>
          <div className="thirdCol footerCol">
          {thirdCol && thirdCol.node.showMicrocopy ? 
            <>
            <h3>{thirdCol.node.singleLineContent ? thirdCol.node.singleLineContent : null}</h3>
            <div dangerouslySetInnerHTML={{ __html: thirdColContent ? thirdColContent : null }}  />
            </>
            : null}
            {countryNavItems ? 
            <CountryPicker countrySites={countryNavItems} label={countryNavLabel} />: null }
          </div>
          <div className="fourthCol footerCol">
         
            <>
            <h3>{fourthCol && fourthCol.node.singleLineContent ? fourthCol.node.singleLineContent : null}</h3>
            {footerFacebook && footerFacebook.node.showMicrocopy ? 
            <SomeButton 
            url={footerFacebook.node.singleLineContent}
            iconPath="/facebook_nega.svg"
            alt="ramirent on facebook"
            /> 
            : null}

            {footerYoutube && footerYoutube.node.showMicrocopy ? 
            <SomeButton 
            url={footerYoutube.node.singleLineContent}
            iconPath="/youtube_nega.svg"
            alt="ramirent on youtube"
            /> 
            : null} 
        
            {footerTwitter && footerTwitter.node.showMicrocopy ? 
            <SomeButton 
            url={footerTwitter.node.singleLineContent}
            iconPath="/twitter_nega.svg"
            alt="ramirent on twitter"
            /> 
            : null} 

            {footerInstagram && footerInstagram.node.showMicrocopy ? 
            <SomeButton 
            url={footerInstagram.node.singleLineContent}
            iconPath="/instagram_nega.svg"
            alt="ramirent on instagram"
            /> 
            : null} 

            {footerLinkedin && footerLinkedin.node.showMicrocopy ? 
            <SomeButton 
            url={footerLinkedin.node.singleLineContent}
            iconPath="/linkedin_nega.svg"
            alt="ramirent on linkedin"
            /> 
            : null} 

            </>
          
          </div>
          
        </div>

        <div className="footerBottom">
          <div className="footerBottomGrid">
            <div className="footerCopyright">
                &copy; 2020 Ramirent
            </div>
            <div className="footerBottomLinks">
              {footerBottomLink1 && footerBottomLink1.node.showMicrocopy ?
              <a href={footerBottomLink1.node.singleLineContent} target="_blank" rel="noopener noreferrer">{footerBottomLink1Label.replace(/(<([^>]+)>)/gi, "")}</a>: null}

              {footerBottomLink2 && footerBottomLink2.node.showMicrocopy ?
              <a href={footerBottomLink2.node.singleLineContent} target="_blank" rel="noopener noreferrer">{footerBottomLink2Label.replace(/(<([^>]+)>)/gi, "")}</a>: null}

              {footerBottomLink3 && footerBottomLink3.node.showMicrocopy ?
              <a href={footerBottomLink3.node.singleLineContent} target="_blank" rel="noopener noreferrer">{footerBottomLink3Label.replace(/(<([^>]+)>)/gi, "")}</a>: null}

            </div>
             
          </div>
          
        </div>
       
      </div>
    );
  
  
}

export default connect(state => ({
  microcopy: state.app.microcopy,
  navItems: state.app.navItems
}), null)(Footer);