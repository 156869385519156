import React, { Component } from 'react'
import keygen from "../../utils/keygen";


class CountryPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCountries: false
    }

  }
 

  render() {
    const showCountriesClickHandler = () => {
      this.setState({
        showCountries: !this.state.showCountries
      })
    }
    return (
      <>
          <button
              onClick={showCountriesClickHandler} 
              className="footerChooseCountryBtn">
              {this.props.label}<img src="/chevron-down.svg" alt="" />
            </button>
          <ul>
          {this.state.showCountries ? this.props.countrySites.map(country => {
            return (
            <li key={keygen()}>
              <a href={country.slug} target="_blank" rel="noopener noreferrer">
                {country.title}
                
              </a>
            </li>
            );
          }) : null}
          </ul>
      </>
    )
  }
}
export default CountryPicker;