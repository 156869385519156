import React from 'react';

const SomeButton = ({iconPath, url, altText}) => {
  return (
  
     <a href={url} target="_blank" rel="noopener noreferrer"> 
        <img src={iconPath}alt={altText} />
      </a>   
  
  );
}
export default SomeButton;