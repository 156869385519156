import React from 'react';
import { connect } from "react-redux";
import * as R from 'ramda';
import CookieConsent from 'react-cookie-consent';


 const CookieBanner = ({microcopy}) => {


  const cookieMicrocopy = R.head(R.filter(item => item.node.slug === 'cookie-consent', microcopy));
  const cookieText = R.path(['node', 'content', 'childMarkdownRemark', 'html'], cookieMicrocopy);

  const acceptBtnStyle = {
    background: '#ffdc00',
    color: '#003287',
    borderRadius: 100 + 'px',
    paddingLeft: 25 + 'px',
    paddingRight: 25 + 'px'
  }
  const declineBtnStyle = {
    background: 'transparent',
    color: '#fff'
  }
  return ( 
    <CookieConsent
              location="bottom"
              buttonText="Accept"
              declineButtonText="Decline"
              enableDeclineButton={true}
              cookieName="allowanalytics"
              buttonStyle={acceptBtnStyle}
              declineButtonStyle={declineBtnStyle}
              >
   <div dangerouslySetInnerHTML={{ __html: cookieText ? cookieText : '' }}  />
    </CookieConsent>
  );
}
export default connect(state => ({
  microcopy: state.app.microcopy
}), null)(CookieBanner);